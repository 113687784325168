import { FC } from 'react';
import IconProps from './IconProps';

const TransparencyInformationIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  const attr = { onClick: onClick, className: `inline ${cursor} ${className}` };

  if (gradient) {
    return (
      <svg {...attr} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.2975 15.106C29.8621 14.2454 31.029 14.0133 31.8307 14.6587C34.3396 16.6788 36.4461 19.1615 38.0323 21.978C38.3381 22.5482 38.4982 23.1852 38.4982 23.8323C38.4982 24.4794 38.3381 25.1164 38.0323 25.6866C37.3948 26.9127 31.4793 37.3337 20.1387 37.3337C8.79819 37.3337 2.88271 26.9127 2.24519 25.7173C1.93935 25.1471 1.7793 24.51 1.7793 23.863C1.7793 23.2159 1.93935 22.5789 2.24519 22.0086C3.82745 19.1783 5.93415 16.6823 8.44688 14.6509C9.24794 14.0034 10.4164 14.2346 10.9814 15.0959C11.5265 15.9269 11.3038 17.035 10.5372 17.6675C8.49473 19.3528 6.78069 21.4082 5.48798 23.7312C5.46638 23.7673 5.45497 23.8086 5.45497 23.8507C5.45497 23.8928 5.46638 23.9341 5.48798 23.9702C6.08872 25.0736 10.9437 33.6557 20.1387 33.6557C29.3338 33.6557 34.1888 25.0736 34.7895 23.9641C34.8111 23.928 34.8225 23.8867 34.8225 23.8446C34.8225 23.8025 34.8111 23.7612 34.7895 23.725C33.4958 21.409 31.7829 19.3601 29.7433 17.6801C28.9753 17.0474 28.7517 15.938 29.2975 15.106ZM15.0431 27.2531C14.3695 26.245 14.01 25.0598 14.01 23.8474C14.01 22.2216 14.6559 20.6624 15.8055 19.5128C16.9551 18.3632 18.5143 17.7174 20.1401 17.7174C21.3525 17.7174 22.5376 18.0769 23.5457 18.7505C24.5538 19.424 25.3395 20.3814 25.8035 21.5015C26.2674 22.6217 26.3888 23.8542 26.1523 25.0433C25.9158 26.2324 25.3319 27.3247 24.4746 28.182C23.6173 29.0393 22.5251 29.6231 21.336 29.8596C20.1469 30.0962 18.9143 29.9748 17.7942 29.5108C16.6741 29.0468 15.7167 28.2611 15.0431 27.2531ZM22.1788 25.2097C22.4483 24.8064 22.5921 24.3324 22.5921 23.8474C22.5921 23.1971 22.3337 22.5734 21.8739 22.1136C21.414 21.6537 20.7904 21.3954 20.1401 21.3954C19.6551 21.3954 19.181 21.5392 18.7778 21.8086C18.3746 22.0781 18.0603 22.461 17.8747 22.9091C17.6891 23.3571 17.6405 23.8501 17.7352 24.3258C17.8298 24.8014 18.0633 25.2383 18.4062 25.5812C18.7491 25.9242 19.186 26.1577 19.6617 26.2523C20.1373 26.3469 20.6304 26.2984 21.0784 26.1128C21.5264 25.9272 21.9094 25.6129 22.1788 25.2097Z"
          fill="url(#paint0_linear_11030_107455)"
        />
        <path
          d="M30.1005 6.07924C30.7181 5.14364 30.3766 3.87281 29.3326 3.46461C26.4098 2.3219 23.2888 1.74729 20.1377 1.77917C16.9877 1.74631 13.8675 2.31908 10.9448 3.45929C9.9 3.86692 9.5579 5.13832 10.1757 6.07434C10.6836 6.84369 11.6749 7.11728 12.5383 6.79427C14.9635 5.88698 17.5394 5.43207 20.1377 5.45719C22.7361 5.43315 25.3118 5.88956 27.7362 6.79864C28.6 7.12251 29.5923 6.84914 30.1005 6.07924Z"
          fill="#5A5766"
        />
        <path
          d="M20.1364 10.3609C18.7443 10.3586 17.3597 10.5211 16.011 10.8433C14.8379 11.1235 14.3973 12.4902 15.0582 13.4992C15.5235 14.2096 16.403 14.5099 17.2344 14.336C19.149 13.9355 21.1262 13.9357 23.0408 14.3365C23.8712 14.5104 24.7498 14.2117 25.2166 13.5033C25.8821 12.4933 25.4417 11.1216 24.2651 10.8412C22.9153 10.5195 21.5295 10.3576 20.1364 10.3609Z"
          fill="#5A5766"
        />
        <defs>
          <linearGradient id="paint0_linear_11030_107455" x1="1.7793" y1="21.319" x2="31.4526" y2="41.5223" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg {...attr} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5773 9.06345C17.9161 8.5471 18.6162 8.40781 19.0972 8.79509C20.6026 10.0071 21.8665 11.4967 22.8182 13.1866C23.0017 13.5288 23.0977 13.911 23.0977 14.2992C23.0977 14.6875 23.0017 15.0697 22.8182 15.4118C22.4357 16.1474 18.8864 22.4001 12.0821 22.4001C5.27774 22.4001 1.72846 16.1474 1.34594 15.4302C1.16244 15.0881 1.06641 14.7059 1.06641 14.3176C1.06641 13.9294 1.16244 13.5472 1.34594 13.205C2.2953 11.5068 3.55932 10.0092 5.06696 8.79041C5.54759 8.40187 6.24866 8.54063 6.58766 9.0574C6.91475 9.55601 6.78111 10.2208 6.32115 10.6004C5.09566 11.6115 4.06724 12.8447 3.29161 14.2385C3.27865 14.2602 3.27181 14.285 3.27181 14.3103C3.27181 14.3355 3.27865 14.3603 3.29161 14.382C3.65206 15.044 6.56505 20.1933 12.0821 20.1933C17.5991 20.1933 20.5121 15.044 20.8725 14.3783C20.8855 14.3566 20.8923 14.3318 20.8923 14.3066C20.8923 14.2813 20.8855 14.2565 20.8725 14.2349C20.0963 12.8452 19.0686 11.6159 17.8448 10.6079C17.384 10.2283 17.2499 9.56265 17.5773 9.06345ZM9.02419 16.3518C8.62005 15.747 8.40434 15.0358 8.40434 14.3084C8.40434 13.3329 8.79184 12.3974 9.4816 11.7077C10.1714 11.0179 11.1069 10.6304 12.0824 10.6304C12.8098 10.6304 13.5209 10.8461 14.1257 11.2502C14.7306 11.6544 15.202 12.2288 15.4804 12.9009C15.7588 13.573 15.8316 14.3125 15.6897 15.0259C15.5478 15.7394 15.1975 16.3948 14.6831 16.9092C14.1687 17.4235 13.5134 17.7738 12.7999 17.9157C12.0864 18.0577 11.3469 17.9848 10.6748 17.7064C10.0028 17.4281 9.42834 16.9566 9.02419 16.3518ZM13.3056 15.1258C13.4673 14.8838 13.5536 14.5994 13.5536 14.3084C13.5536 13.9182 13.3986 13.544 13.1227 13.2681C12.8467 12.9922 12.4725 12.8372 12.0824 12.8372C11.7914 12.8372 11.5069 12.9235 11.265 13.0851C11.0231 13.2468 10.8345 13.4766 10.7231 13.7454C10.6118 14.0142 10.5826 14.31 10.6394 14.5954C10.6962 14.8808 10.8363 15.1429 11.0421 15.3487C11.2478 15.5545 11.5099 15.6946 11.7953 15.7513C12.0807 15.8081 12.3765 15.779 12.6454 15.6676C12.9142 15.5563 13.144 15.3677 13.3056 15.1258Z"
        fill="currentColor"
      />
      <path
        d="M18.0595 3.64751C18.4301 3.08615 18.2252 2.32365 17.5988 2.07873C15.8451 1.39311 13.9725 1.04834 12.0819 1.06747C10.1918 1.04775 8.3197 1.39141 6.56613 2.07554C5.93922 2.32012 5.73396 3.08296 6.10467 3.64457C6.40937 4.10618 7.00417 4.27033 7.52221 4.07653C8.97731 3.53215 10.5229 3.25921 12.0819 3.27428C13.6409 3.25986 15.1863 3.5337 16.641 4.07915C17.1592 4.27347 17.7546 4.10945 18.0595 3.64751Z"
        fill={accentColor}
      />
      <path
        d="M12.0824 6.2166C11.2472 6.21521 10.4164 6.31275 9.6072 6.50604C8.90335 6.67417 8.63896 7.49421 9.03549 8.09956C9.31471 8.52583 9.84241 8.70603 10.3412 8.60168C11.49 8.36136 12.6763 8.36145 13.8251 8.60198C14.3233 8.7063 14.8505 8.5271 15.1305 8.10205C15.5298 7.49604 15.2656 6.67301 14.5597 6.50476C13.7498 6.31174 12.9183 6.21463 12.0824 6.2166Z"
        fill={accentColor}
      />
    </svg>
  );
};

export default TransparencyInformationIcon;
